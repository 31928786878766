<template>
  <div>
    <Sidebar />
    <div class="new-header">
      <div class="main-header-inr">
        <div class="header-left">
          <button @click="toggleBodyClass" class="btn toggle-btn">
            <img src="./../../assets/img/icon-bars.svg" alt="" />
          </button>
          <!-- <a href="/" class="navbar-brand">
            <span>
              <img src="./../../assets/img/redline-new-logo.svg" alt="" />
            </span>
          </a> -->
        </div>
        <div class="header-middle" v-if="$vuetify.breakpoint.width > 910">
          <ul class="main-nav">
            <!-- Button for each menu item, excluding user type 3 -->
            <li v-for="item in user.user_type === 3 ? [] : menus" :key="item.title"
              @click="conformDailogOpen(item.link)" :class="[
                isActiveRoute(item) ? 'new-menus active-btn' : 'new-menus',
              ]">
              <img :src="item.img" alt="" />
              <span v-html="$t(item.title)"></span>
            </li>

            <!-- Button for user type 3 specific menu items -->
            <li v-for="item in user.user_type === 3 ? user3Menu : []" :key="item.title" :href="item.link"
              :target="item.title.toLowerCase() === 'navbar.carhistoryreport' ? '_blank' : '_self'" :class="[
                'btn',
                'text-btn',
                isActiveRoute(item) ? 'active-btn' : '',
                'flex-column-class'
              ]">
              <!-- <span class="icon" :class="getIconClass(item.title)">{{ item.icon }}</span> -->
              <img :src="item.img" alt="" />
              <p v-html="$t(item.title)"></p>
            </li>
          </ul>
        </div>
        <v-spacer></v-spacer>
        <!-- user profile -->
        <!--  TODO: Hide this button until further notice   -->
        <!--    <v-select
      :menu-props="{ top: false, offsetY: true }"
      v-model="lang"
      :item-value="(item) => item.lang"
      :items="items"
      @change="changeLanguage(lang)"
      solo
      class="custom-select language-select mr-4"
    >
      <template v-slot:selection="{ item }">
        <img :src="item.image" width="34" class="" />
      </template>
<template v-slot:item="{ item }">
        <img :src="item.image" width="25" class="mr-3 mt-2 mb-2 pa-1" /><span>{{
          item.name
        }}</span>
      </template>
<template v-slot:item="{ item }">
        <img class="ma-2" width="25" :src="item.image" />{{ item.name }}
      </template>
</v-select>-->

        <div class="header-right">
          <!-- <v-btn exact color="white" class="flex-column-class" text>
            <b size="35" left medium class="mb-2" style="font-size: 22px">
              714-334-5505
            </b>
            <p style="font-size: 20px; display: contents">
              {{ $t("navbar.customerSupport") }}
            </p>
          </v-btn> -->
          <div class="online-members" elevation="0" v-if="$vuetify.breakpoint.width > 767">
            <h4>Online <br />Members</h4> <label class="online-m-count"><span id="participantCount"> 0 </span></label>
          </div>
          <!-- <v-autocomplete class="roomSelect" outlined v-model="room" :items="roomList" @change="changeRoom"
            item-text="name" item-value="code" :label="$t('navbar.room')" :placeholder="$t('navbar.room')" solo
            :dense="true" hide-details="auto" return-value></v-autocomplete> -->
          <!-- <v-autocomplete v-if="$vuetify.breakpoint.width > 1100" class="room-select" outlined v-model="room"
            :items="roomList" @change="changeRoom" item-text="name" item-value="code" :label="$t('navbar.room')"
            return-value></v-autocomplete> -->
          <v-btn class="btn customBtn pattern-btn" @click="openChat = true"
            v-if="currentRouteName.includes('dashboard') && user.user_type != 3">
            <img src="../../assets/img/chat.svg" alt="" />
            <v-badge color="red" class="mb-6" :content="badgeCount" v-if="badgeCount !== 0" />
          </v-btn>
          <div class="rd-user-dtl">
            👋
            <p>{{ this.user.user_detail.representative_name }}</p>
          </div>
          <v-btn class="btn customBtn pattern-btn logOut-btn" @click="doLogout">
            <img src="../../assets/img/logout.svg" alt="" />
          </v-btn>
        </div>
      </div>
    </div>
    <chat :show="openChat" @countUnseen="getUnseen" @updateShow="openChat = false"
      v-if="currentRouteName.includes('dashboard')" />
    <v-dialog hide-overlay :attach="true" v-model="conformDailog" max-width="700px"
      :content-class="'hide-overflow-y image-slider'" persistent>
      <v-card>
        <v-card-text class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
          <div class="pa-2">
            <v-card-title style="flex-direction: column">
              {{ $t("invoice.confirmChangeMenuText") }}</v-card-title>
            <v-container class="text-center">
              <v-card-actions class="mt-3">
                <v-spacer></v-spacer>
                <v-btn height="40" style="font-size: 16px !important" large
                  class="red darken-4 red-gradient white--text text-capitalize" @click="conformDailog = !conformDailog">
                  {{ $t("invoice.no") }}
                </v-btn>
                <v-btn height="40" style="font-size: 16px !important" large
                  class="green darken-4 green-gradient white--text text-capitalize" @click="goToNext()">
                  {{ $t("invoice.yes") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
import SocketMixin from "@/mixins/SocketMixin";
import chat from "../chat/Chat";
import Sidebar from "./Sidebar.vue";
const routeRelationships = {
  '/dashboard/vehicle-history': [
    '/dashboard/previous-vehicle-history',
  ],

  //relationship with the parent route to child route
  '/dashboard/billing-invoice': [
    '/dashboard/billing-invoice/dashboard',
    '/dashboard/billing-invoice/create'
  ],
  // Add other parent-child relationships as needed
};
export default {
  name: "Navbar",
  components: {
    chat,
    Sidebar
  },
  mixins: [SocketMixin.listeners],
  data() {
    return {
      room: "",
      badgeCount: 0,
      lang: localStorage.getItem("lang"),
      openChat: false,
      items: [
        {
          name: "English",
          lang: "en",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          name: "Spanish",
          lang: "es",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/128-spain.svg",
        },
      ],
      sideNav: null,
      menus: [
        {
          // icon: "mdi-account-multiple",
          title: "navbar.carHistory",
          link: "/dashboard/vehicle-history",
          img: require('@/assets/img/car-history.svg')
        },
        {
          // icon: "mdi-text-box-search",
          title: "navbar.inventory",
          link: "/dashboard/serach-inventory",
          img: require('@/assets/img/car-with-parts.svg')
        },
        {
          // icon: "mdi-cogs",
          title: "navbar.interchange",
          link: "/dashboard/interchange",
          img: require('@/assets/img/interchange-1.svg')
        },
        {
          // icon: "mdi-receipt",
          title: "navbar.oInvoice",
          link: "/dashboard/billing-invoice",
          img: require('@/assets/img/invoice.svg')
        },
        {
          // icon: "mdi-account-multiple",
          title: "navbar.membersDirectory",
          link: "/dashboard/",
          img: require('@/assets/img/members.svg')
        },
        // {
        //   // icon: "mdi-account-tie-voice",
        //   title: "navbar.marketingCampaign",
        //   link: "/dashboard/marketing-campaign",
        //   img: require('@/assets/img/members.svg')
        // },
        // {
        //   icon: "mdi-credit-card-search",
        //   title: "navbar.vinSearch",
        //   link: "/dashboard/vin-search",
        // },
        // {
        //   // icon: "mdi-book-open-variant",
        //   title: "navbar.booklog",
        //   link: "/dashboard/booklog",
        //   img: require('@/assets/img/members.svg')
        // },
        /* {
          icon: "mdi-text-box-multiple",
          title: "navbar.dmv",
          link: "/dashboard/dmv-processing",
        }, */
        /*{
          icon: "mdi-firework",
          title: "navbar.auction",
          link: "/dashboard/car-auctions",
        },
        {
          icon: "mdi-cart",
          title: "navbar.trade",
          link: "/dashboard/trade-car-parts",
        },
        {
          icon: "mdi-cart",
          title: "navbar.sellingEbay",
          link: "/dashboard/selling-ebay",
        },*/
        // {
        //   // icon: "mdi-fire",
        //   title: "navbar.hotlineNews",
        //   link: "/dashboard/hotline-news",
        // },
        // {
        //   // icon: "mdi-recycle",
        //   title: "navbar.coreBuyingMember",
        //   link: "/core-buying/",
        // },
      ],
      user3Menu: [
        {
          icon: "mdi-account-multiple",
          title: "navbar.membersDirectory",
          link: "/dashboard/",
          img: require('@/assets/img/members.svg')
        },
        // {
        //   icon: "mdi-receipt",
        //   title: "navbar.carHistoryReport",
        //   link: process.env.VUE_APP_VIN_REPORTS_LINK,
        // },
        // {
        //   icon: "mdi-recycle",
        //   title: "navbar.coreBuying",
        //   link: "/core-buying-service/",
        // },
      ],
      conformDailog: false,
      link: null,
      websiteServicesLink: process.env.VUE_APP_WEBSITE_SERVICES_LINK,
      reportDialog: false,
      reportDialogMessage: "",
      reportData: null,
      inventoryUrl: process.env.VUE_APP_INVENTORY_URL,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      userServices: "user/getUserServices",
      roomList: "members/getRoomList",
      newsTotalUnreadCount: "news/getNewsTotalUnread",
    }),
    currentRouteName() {
      return this.$route.path;
    },
  },

  mounted() {
    this.room = localStorage.getItem("room")
      ? localStorage.getItem("room")
      : null;
    this.getRooms();
    document.getElementById("participantCount").innerText =
      sessionStorage.getItem("onlineUsers")
        ? sessionStorage.getItem("onlineUsers")
        : this.user.online_users_count;
    this.newsTotalUnread();
    this.$root.$on("updateUnreadnews", () => {
      setTimeout(() => {
        this.newsTotalUnread();
      }, 500);
    });
  },

  methods: {

    // Method to check if the route is active
    isActiveRoute(item) {

      const currentPath = this.$route.path;
      const mainRoute = item.link;

      if (currentPath === mainRoute) {
        return true;
      }

      const childRoutes = routeRelationships[mainRoute];

      if (childRoutes && childRoutes.includes(currentPath)) {
        return true;
      }

      return false;
      // return this.$route.path.startsWith(item.link);
      // return this.$route.path === item.link;

      // console.log("kk", item.title, this.$route.name);
      // return (
      //   item.title.toLowerCase() === `navbar.${this.$route.name}`.toLowerCase() ||
      //   (item.title === 'navbar.marketingCampaign' &&
      //     ['MarketingCampaign', 'MarketingPhone', 'MarketingEmail', 'AddCampaign', 'HistoryCampaign'].includes(this.$route.name)) ||
      //   (item.title === 'navbar.hotlineNews' && ['HotlineNews', 'ViewHotlineNews'].includes(this.$route.name))
      // );
    },
    // Method to get the appropriate icon class
    getIconClass(title) {
      const specificTitles = [
        'navbar.membersDirectory',
        'navbar.carHistoryReport',
        // 'navbar.vinSearch',
        'navbar.interchange',
        // 'navbar.booklog',
        // 'navbar.marketingCampaign',
        'navbar.searchInventory',
        // 'navbar.coreBuyingMember',
        'navbar.invoice',
      ];
      return specificTitles.includes(title) ? 'mb-2' : 'mb-2 coming-soon-menu-color';
    },




    toggleBodyClass() {
      document.body.classList.toggle('sidebar-active');
    },
    removeBodyClass() {
      document.body.classList.remove('sidebar-active');
    },
    ...mapActions({
      logout: "user/logout",
      getRooms: "members/getRoomList",
      newsTotalUnread: "news/newsTotalUnread",
    }),

    changeRoom() {
      localStorage.setItem("room", this.room);
      location.reload();
    },

    setAvtarIcon() {
      if (this.user && this.user.user_detail) {
        if (this.user.parent_id) {
          return this.user.user_detail.representative_name.substring(0, 1);
        }
        return this.user.user_detail.company_name.substring(0, 1);
      }
      return "";
    },

    async doLogout() {
      try {
        await this.logout();
        sessionStorage.removeItem("onlineUsers");
        window.location.reload();
      } catch ({ message }) {
        console.log(message);
      }
    },
    async changeLanguage() {
      await loadLanguageAsync(this.lang);
      localStorage.setItem("lang", this.lang);
    },

    getUnseen(data) {
      if (data != 0 && data != this.badgeCount) {
        const sound = require("../../assets/chattone.mp3");
        new Audio(sound).play();
      }
      this.badgeCount = data;
    },
    conformDailogOpen(nextLink) {
      if (this.$route.name == "BillingInvoiceCreate") {
        this.conformDailog = true;
        this.link = nextLink;
      } else {
        this.$router.push(nextLink);
      }
    },
    goToNext() {
      this.conformDailog = false;
      this.$router.push(this.link);
    },
  },
};
</script>
